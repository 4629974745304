<template>
  <div>
    <h1>An email was sent... Please click on the link to confirm email</h1>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>